<template>
  <el-container>
    <el-header height="220">
      <el-row>
        <el-select
          v-model="queryInfo.subjectId"
          clearable
          placeholder="请选择科目"
          style="margin-left: 5px"
          @change="subjectChange"
        >
          <el-option
            v-for="item in allSubject"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
      </el-row>
    </el-header>

    <el-main>
      <el-table
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed="left"
          label="No"
          type="index"
        />
        <el-table-column
          prop="examName"
          label="考试名称"
          width="150"
        />
        <el-table-column
          prop="type"
          label="考试类型"
          width="90"
        />
        <el-table-column
          prop="startTime"
          label="考试时间"
          width="150"
        />
        <el-table-column
          prop="duration"
          label="考试时长(分钟)"
          width="150"
        />
        <el-table-column
          prop="totalScore"
          label="考试总分"
        />
        <el-table-column
          prop="passScore"
          label="及格分数"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="320"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              @click="prepareExam(scope.$index, scope.row)"
            >去考试</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :small="screenWidth <= 768"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>

    <el-dialog
      title="考试提示"
      :visible.sync="startExamDialog"
      center
      width="50%"
    >
      <el-alert
        title="点击`开始考试`后将自动进入考试，请诚信考试，考试过程中可能会对用户行为、用户视频进行截图采样，请知悉！"
        type="error"
      />
      <el-card style="margin-top: 25px">
        <span>考试名称：</span>{{ currentSelectedExam.examName }}
        <br>
        <span>考试描述：</span>{{ currentSelectedExam.examDesc }}
        <br>
        <span>考试时长：</span>{{ currentSelectedExam.duration + '分钟' }}
        <br>
        <span>试卷总分：</span>{{ currentSelectedExam.totalScore }}分
        <br>
        <span>及格分数：</span>{{ currentSelectedExam.passScore }}分
        <br>
        <span>开放类型：</span> {{ currentSelectedExam.type === 2 ? '需要密码' : '完全公开' }}
        <br>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="startExamDialog = false">返 回</el-button>
        <el-button type="primary" @click="startExam(currentSelectedExam.examId)">开始考试</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { getSubjectKV, getPapers } from '@/api/exam'

export default {
  name: 'ExamIndex',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      queryInfo: {
        subjectId: null,
        pageNumber: 1,
        pageSize: 10
      },
      allSubject: [],
      // 开始考试的提示框
      startExamDialog: false,
      // 当前选中的考试的信息
      currentSelectedExam: {
        examId: 114511
      }
    }
  },
  created() {
    document.title = '我的试卷'
    this.getData(this.queryInfo)
    this.getSubjects()
    // this.getQuestionTypes()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getData(this.queryInfo)
      // 回到顶部
      scrollTo(0, 0)
    },
    getData(queryInfo) {
      getPapers(queryInfo).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    getSubjects() {
      getSubjectKV().then((resp) => {
        if (resp.code === 0) {
          this.allSubject = resp.data
        }
      })
    },
    // 题库变化
    subjectChange(val) {
      this.queryInfo.subjectId = val
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getData(this.queryInfo)
    },
    prepareExam(index, row) {
      row.password = 12345678
      row.type = 1
      if (row.type === 2) {
        this.$prompt('请提供考试密码', 'Tips', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          if (value === row.password) {
            this.startExamDialog = true
            this.currentSelectedExam = row
          } else {
            this.$message.error('密码错误o(╥﹏╥)o')
          }
        }).catch(() => {
        })
      } else {
        this.startExamDialog = true
        this.currentSelectedExam = row
      }
    },
    startExam(paperId) {
      const routeData = this.$router.resolve({ path: '/exam/starts/' + paperId })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style>
</style>
